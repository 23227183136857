.alert-order {
  padding: 32px 2px 0;
  font-size: 16px;

  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    padding: 60px 2px 0;
    // font-size: 24px;
  }

  .no-map.address {
    font-size: 20px;
    line-height: 20px;
    text-align: center;
  }

  .info-box {
    font-size: 16px;
  }

  .nav-header {
    margin-bottom: 15px;

    .back {
      display: none;
    }
  }

  .btn-primary {
    position: relative;
  }

  .notice {
    &.notice-indent {
      margin: 13px 0 18px;
      color: #fff;

      @media (min-width: map-get($grid-breakpoints, 'sm')) {
        margin: 26px 0 58px;
      }
    }
  }

  .map-box {
    padding: 6px 6px 9px;
    margin: 0;
    background: $white;
    border-radius: 0;
    img {
      max-width: 100%;
      width: 100%;
      height: auto;
    }
  }

  .address {
    text-transform: none;
    font-size: 13px;
    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      font-size: 27px;
    }
  }

  .info-box {
    font-size: 19px;
    font-weight: 700;

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      font-size: 30px;
    }
  }

  .info-bottom {
    font-weight: 400;
  }

  .see-map {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 2px;
    margin: 20px auto 10px;
    color: #fff;
    text-align: center;

    @media (max-width: map-get($grid-breakpoints, 'sm')) {
      font-size: 12px;
      letter-spacing: 1px;
    }
  }
}
