.WaitOrder {
  color: map-get($theme-colors, 'font-wait-order');
  padding: 32px 2px 0;

  margin-left: 15px;
  margin-right: 15px;

  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    padding: 60px 2px 0;
    margin-left: auto;
    margin-right: auto;
  }

  h2 {
    margin: 15px 0;
    font-weight: 700;
  }

  .nav-header {
    margin-bottom: 15px;

    .back {
      display: none;
    }
  }

  .info-box {
    margin-left: -15px;
    margin-right: -15px;
    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      font-size: 20px;
    }
  }

  .order-info {
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    padding: 15px;
    text-transform: none;

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      font-size: 24px;
      line-height: 28px;
      padding: 32px 0 28px;
    }

    p {
      padding: 0 20px;
      margin: 0 0 10px;
    }

    .order-loader {
      width: 196px;
      height: 196px;
      margin: 0 auto 13px;
      position: relative;
      background: url(map-get($image-paths, 'loader')) no-repeat center/contain;

      @media (min-width: map-get($grid-breakpoints, 'sm')) {
        width: 392px;
        height: 390px;
        margin: 0 auto 26px;
      }

      .indicater {
        position: absolute;
        width: 196px;
        height: 196px;
        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          width: 392px;
          height: 390px;
        }
      }

      .indicate10 {
        background: url(map-get($image-paths, 'loader-1')) no-repeat center/contain;
      }

      .indicate20 {
        background: url(map-get($image-paths, 'loader-2')) no-repeat center/contain;
      }

      .indicate30 {
        background: url(map-get($image-paths, 'loader-3')) no-repeat center/contain;
      }

      .indicate40 {
        background: url(map-get($image-paths, 'loader-4')) no-repeat center/contain;
      }

      .indicate50 {
        background: url(map-get($image-paths, 'loader-5')) no-repeat center/contain;
      }

      .indicate60 {
        background: url(map-get($image-paths, 'loader-6')) no-repeat center/contain;
      }

      .indicate70 {
        background: url(map-get($image-paths, 'loader-7')) no-repeat center/contain;
      }

      .indicate100 {
        background: url(map-get($image-paths, 'loader-8')) no-repeat center/contain;
      }
      

      .fade {
        -moz-animation: blink normal 3s infinite ease-in-out;
        -webkit-animation: blink normal 3s infinite ease-in-out;
        -ms-animation: blink normal 3s infinite ease-in-out;
        animation: blink normal 3s infinite ease-in-out;
        @-moz-keyframes blink {
          0% {
            opacity:0;
          }
          50% {
            opacity:1;
          }
          100% {
            opacity:0;
          }
        }
        
        @-webkit-keyframes blink {
          0% {
            opacity:0;
          }
          50% {
            opacity:1;
          }
          100% {
            opacity:0;
          }
        }
        
        @-ms-keyframes blink {
          0% {
            opacity:0;
          }
          50% {
            opacity:1;
          }
          100% {
            opacity:0;
          }
        }
        
        @keyframes blink {
          0% {
            opacity:0;
          }
          50% {
            opacity:1;
          }
          100% {
            opacity:0;
          }
        }
      }
    }

    .waiting-time {
      font-size: 27px;
      line-height: 30px;
      letter-spacing: 2px;
      margin: 0 0 17px;

      @media (min-width: map-get($grid-breakpoints, 'sm')) {
        font-size: 54px;
        line-height: 58px;
        letter-spacing: 4px;
        margin: 0 0 38px;
      }
    }

    .map-box {
      padding: 6px 6px 9px;
      margin: 21px 0 0;
      background: map-get($theme-colors, 'background-wait-order-map-box');
      border-radius: 0px;
      color: map-get($theme-colors, 'font-wait-order-map-box');

      @media (min-width: map-get($grid-breakpoints, 'sm')) {
        padding: 13px 13px 20px;
        margin: 42px 0 0;
        border-radius: 0px;
      }

      img {
        max-width: 100%;
        height: auto;
        width: 100%;
      }
    }

    .email-info {
      text-align: center;
      padding-top: 10px;
    }
  }

  .problem-info {
    text-align: center;
    padding: 10px;
    text-transform: none;
    .phone-link {
      text-decoration: underline;
      color: white;
      margin: 0 5px;
    }
  }
}

.modal {
  .pick-now {
    .modal-header {
      .modal-title {
        font-size: 24px;
        line-height: 34px;
        color: map-get($theme-colors, 'font-wait-order-modal-title');
        font-weight: 400;
        text-transform: uppercase;
        margin: auto;
        font-family: 'ATC Duel';
        text-align: center;
      }
    }
    .modal-body {
      text-align: center;
    }

    .modal-footer {
      justify-content: center;
      a {
        font-size: 19px;
        letter-spacing: 1px;
        width: 90%;
        text-transform: uppercase;
        .fa-chevron-right {
          margin-top: 3px;
        }
      }
      .btn.btn-primary {
        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          padding: 15px;
          width: 90%;
          font-size: 26px;
          letter-spacing: 4px;
          padding: 15px;
          border-width: 4px;
        }
      }
    }
  }
}

.Modal {
  &.order-cancelled {
    .modal-header {
      .modal-title {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        color: map-get($theme-colors, 'font-modal-order-cancelled-title');
        font-family: 'Montserrat', sans-serif;
        text-transform: inherit;
        margin: 0;
      }
    }
    .modal-body {
      p {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 10px;
      }
    }
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
