@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';

@import './variables';

// Bootstrap
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/bootstrap';

@function get-colour($colourName) {
  @return map-get($theme-colors, $colourName);
}

// General Imports
@import './alertOrder';
@import './card-notice';
@import './checkbox';
@import './confirmContact';
@import './confirmOrder';
// @import './contactInfo';
@import './creditCardIcon';
@import './detail-box';
@import './emailCaptureModal';
@import './emailCaptureModalApp';
@import './followingBalls';
@import './footer.scss';
@import './info-box';
@import './loading-indicator';
@import './loadingIndicator';
@import './menuItemOptions';
@import './menuListWithOptions';
@import './menuMaxItemAlertModal';
@import './message';
@import './meuSelectedItemOption';
@import './modal-rc';
@import './modal';
@import './orderFlow';
@import './orderSummaryInfo';
@import './orderSummaryList';
@import './payment-loader-modal';
@import './payment';
@import './paymentLoaderModal';
@import './placeOrder';
@import './policy.scss';
// @import './refundDataPolicy';
@import './refundPolicy';
@import './reusableStyles';
@import './spinner';
@import './start';
// @import './styles';
// @import './terms';
@import './waitOrder';
@import './delivery';
@import './discoverVendor.scss';

// Font Awesome
@import './font-awesome/fontawesome';
@import './font-awesome/fa-solid';
@import './font-awesome/fa-brands';
@import './font-awesome/fa-regular';

body {
  min-width: 320px;
  font-size: 11px;
  line-height: 14px;
  color: get-colour('font-default');
  position: relative;
  background-color: get-colour('body-default');
  font-family: $fontFamily, 'sans-serif';
  text-transform: uppercase;
  overflow-x: hidden;

  @media (min-width: $sm-breakout) {
    font-size: 18px;
    line-height: 22px;
  }

  &.home {
    background-color: $homeBackgroundColor;

    .mobile-wrapper {
      max-width: map-get($grid-breakpoints, 'sm');
      height: 100vh;
    }

    .container {
      @media (max-width: map-get($grid-breakpoints, 'sm')) {
        padding: 0;
      }
    }

    .footer {
      color: get-colour('font-footer');

      .btn {
        color: get-colour('font-footer');
      }
    }
  }
}

.btn,
a.btn {

  &.btn-primary{
    text-transform: uppercase;
  }

  &.btn-primary,
  &.btn-secondary {
    border-radius: 0px;
    font-size: 20px;
    width: 100%;
    padding: 10px;
    letter-spacing: 2px;

    .forward-icon {
      position: absolute;
      right: 10px;
      line-height: 1;

      @media (min-width: $sm-breakout) {
        margin-top: 0;
      }
    }
  }

  &.btn-outline {
    font-size: 20px;
    width: 100%;
    padding: 10px;
    color: get-colour('#ffffff');

    border: 1px solid get-colour('border-btn-outline');
  }

  &.btn-link {
    padding: 0;
    vertical-align: unset;
    color: #ffffff;
  }
  // &.btn-default {
  //   display: inline-block;
  //   margin: 0 5px 4px 0;
  //   font-size: 13px;
  //   line-height: 16px;
  //   font-weight: 400;
  //   padding: 4px 10px;
  //   border-width: 2px;
  //   border-style: solid;
  //   letter-spacing: 0px;
  //   color: #3c3e3f;
  //   background-color: $white;
  //   // border-color: #f25a29;
  // }

  &.btn-payment {
    background-color: #000000;
    border-color: #000000;
    border-radius: 4px;
    height: 40px;
    box-shadow: none;
    padding: 0px;
  }

  &.btn-payment:hover {
    background-color: #3C4043;
    border-color: #3C4043;
  }

  &.btn-payment:active {
    background-color: #3C4043 !important;
    border-color: #3C4043 !important;
    box-shadow: none !important;
  }


  &.btn-arrow {
    &::after {
      content: '';
      position: absolute;
      right: 20px;
      top: 50%;
      margin-top: -18px;

      background: url(map-get($image-paths, 'icon_arrow')) no-repeat center /
        contain;
      width: 18px;
      height: 36px;
      color: inherit;
    }
  }

  &.btn-reverse-arrow {
    &::after {
      content: '';
      position: absolute;
      left: 20px;
      top: 50%;
      margin-top: -18px;
      background: url(map-get($image-paths, 'icon_arrow')) no-repeat center /
        contain;
      width: 18px;
      height: 36px;
      color: inherit;
      transform: scaleX(-1);
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: get-colour('headings-default');
  font-weight: 900;
}

h4 {
  text-transform: uppercase;
}

h2 {
  font-size: 16px;
  line-height: 28px;
  color: get-colour('heading-2');
  text-align: center;
  letter-spacing: 0px;
  font-weight: 100;
}

h6 {
  line-height: 18px;
  color: get-colour('heading-6');
  margin: 0 0 5px;
  font-size: 12px;
  letter-spacing: 1px;
}

.highlighted {
  color: get-colour('primary');
}

.container {
  .Spinner .sk-circle .sk-child {
    &:before {
      background-color: get-colour('background-spinner');
    }
  }
}

.back-icon {
  color: get-colour('font-back-icon');
  float: left;
  margin-right: 5px;
}
.forward-icon {
  float: right;
}

@media (min-width: map-get($grid-breakpoints, 'sm')) {
  body {
    font-size: 18px;
    line-height: 22px;
  }
}

// h5 {
//   line-height: 34px;
//   color: #f25a29;
//   font-family: 'ATC Duel';
//   font-weight: normal;
//   text-align: center;
//   margin: 0 0 12px;
// }

@media (min-width: map-get($grid-breakpoints, 'sm')) {
  h4 {
    font-size: 56px;
    line-height: 62px;
    margin: 45px 0 21px;
  }

  h5 {
    font-size: 56px;
    line-height: 62px;
    margin: 0 0 25px;
  }

  h6 {
    font-size: 24px;
    line-height: 30px;
    margin: 0 0 13px;
  }

  ul {
    margin: 0 0 12px;
  }
}

// ul {
//   list-style: none;
//   padding: 0;
//   margin: 0 0 2px;
//   li {
//     font-size: 12px;
//     line-height: 15px;
//     color: $black;
//     padding: 0 0 0 10px;
//     background-image: url(../static/images/marker_small.jpg);
//     background-position: 0 8px;
//     background-repeat: no-repeat;
//     @media (min-width: 640px) {
//       font-size: 24px;
//       line-height: 30px;
//       padding: 0 0 0 20px;
//       background-image: url(../static/images/marker.jpg);
//       background-position: 0 15px;
//     }
//   }
// }

.container {
  @media (min-width: 580px) {
    max-width: 100%;
  }
}

.nav-header {
  @media (max-width: map-get($grid-breakpoints, 'sm')) {
    font-size: 11px;

    padding-top: 13px;
    // margin-top: 20px;
  }

  a {
    color: get-colour('nav-link');
    white-space: nowrap;
    margin-left: 4px;
    font-weight: 100;

    font-size: 20px;

    @media (max-width: map-get($grid-breakpoints, 'sm')) {
      font-size: 12px;
    }
  }

  h2 {
    margin-bottom: 0;
  }

  .subheader {
    text-align: center;
    color: get-colour('nav-sub-header');

    .store {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 20px;
      letter-spacing: 2px;

      @media (max-width: map-get($grid-breakpoints, 'sm')) {
        font-size: 12px;
        letter-spacing: 1px;
      }
    }

    .store-name {
      text-transform: none;

      @media (max-width: map-get($grid-breakpoints, 'sm')) {
        font-size: 12px;
      }
    }
  }
}

.quick-link {
  font-size: 18px;
  color: get-colour('font-quick-links');
  text-transform: uppercase;
  text-decoration: none;
  &.pull-right {
    float: right;
  }
}

label {
  display: block;
  font-size: 16px;
  line-height: 20px;
  color: get-colour('font-label-default');
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 5px;
  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    font-size: 25px;
    line-height: 36px;
    letter-spacing: 3px;
    margin-bottom: 12px;
  }
}

select.form-control:not([size]):not([multiple]) {
  height: auto;
  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    height: 65px;
  }
}

.saved-details {
  font-size: 15px;
  line-height: 18px;
  color: get-colour('font-saved-details');
  font-weight: 600;
  text-align: center;
  letter-spacing: 2px;
  margin: 0 0 20px;
  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    font-size: 31px;
    line-height: 36px;
    letter-spacing: 3px;
  }
}

button {
  touch-action: manipulation;
}
