.Modal.EmailCaptureModal {
  .modal-header {
    .modal-title {
      font-family: 'Montserrat', sans-serif;
      text-transform: none;
    }
  }
  .modal-body {
    input {
      border-color: map-get(
        $theme-colors,
        'border-modal-email-capture-body-input'
      );
      border-width: 2px;
      outline: 0;
      &:focus {
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
          0 0 8px rgba(65, 64, 66, 0.6);
        border-color: #736f6e;
      }
    }
  }
  .modal-footer {
    .btn-primary {
      &.disabled {
        opacity: 1;
        color: #6f6f6e !important;
        background-color: #c6c6c6 !important;
      }
    }
  }
}
