.PaymentLoaderModal {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  z-index: 1061;
  .modal-content {
    background-color: map-get($theme-colors, 'background-modal-payment-loader');
    opacity: 0.9;
  }
  .modal-header,
  .modal-footer {
    display: none;
  }
  .modal-body {
    position: relative;
    padding: 40px 15px 15px;

    .processing-icon {
      margin: auto;
      text-align: center;
    }
    .processing-text {
      margin-bottom: 20px;
      padding-top: 5px;
      padding-bottom: 10px;
      font-size: 16px;
      color: map-get($theme-colors, 'font-payment-loader-processing');
      text-align: center;
    }
  }
}

/* Fix to show two modals at the same time. Bootstrap 4 does not support two modals
https://getbootstrap.com/docs/4.0/components/modal/
"Bootstrap only supports one modal window at a time. 
Nested modals aren’t supported as we believe them to be poor user experiences."
*/
.modal-backdrop.fade.show.second-modal {
  z-index: 1060;
}
