.credit-card-icon {
  &.left-icon {
    background-image: url(map-get($image-paths, 'left_icon'));
    &.visa {
      background-position: 0 0;
    }
    &.amex {
      background-position: -50px 0;
    }
    &.mastercard {
      background-position: -100px 0;
    }
    &.han-solo {
      background-position: -150px 0;
    }
    &.maestro {
      background-position: -200px 0;
    }
    &.discover {
      background-position: -250px 0;
    }
    &.jcb {
      background-position: -300px 0;
    }
    &.diners-club {
      background-position: -350px 0;
    }
    &.paypal {
      background-position: -400px 0;
    }
    &.generic {
      background-position: -450px 0;
    }
  }
}
