.order-items {
  .item-group {
    .order-item {
      .selected-item-box {
        margin-top: 5px;
        overflow: hidden;
        .selected-item {
          display: inline-block;
          width: 150px;
          margin: 0;
          font-size: 15px;
          line-height: 20px;
          color: map-get($theme-colors, 'font-order-items-selected-box-item');
          text-align: left;
          text-transform: none;
          padding-left: 95px;
          width: 225px;
          @media (min-width: map-get($grid-breakpoints, 'sm')) {
            padding-left: 120px;
            width: 250px;
          }
        }
      }
    }
  }
}
