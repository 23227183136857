.container {
  .LoadingIndicator {
    .Spinner {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      .sk-circle {
        width: 50px;
        height: 50px;
        .sk-child {
          &:before {
            background: map-get($theme-colors, 'background-loading-indicator');
          }
        }
      }
    }
  }
}
