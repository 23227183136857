.PlaceOrder {
  padding: 30px 2px 100px;

  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    padding: 60px 15px 150px;
    .menu-list-container {
      margin-top: 10px;
    }
  }

  .btn-primary {
    position: relative;
  }

  .menu-list-container {
    margin-top: 10px;

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      margin-top: 30px;
    }
  }

  .quick-link {
    &.pull-right {
      margin-top: 15px;
    }
    &:hover {
      color: map-get($theme-colors, 'font-place-order-quick-link-hover');
    }
  }

  .fixed-btn-row {
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    display: block;
    bottom: 0;
    z-index: 1000;
    padding-top: 20px;
    background-color: map-get(
      $theme-colors,
      'background-place-order-fixed-btn-row'
    );
    .btn-primary,
    .btn-secondary {
      text-transform: uppercase;
      position: relative;
      display: block;
      margin: 0 10px;
      width: 95%;
    }

    .btn-secondary.btn-arrow::after {
      display: none;
    }
  }
}
