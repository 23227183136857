$primary: #d01417;
$secondary: #c6c6c6;
$success: #5cb85c;
$info: #5bc0de;
$warning: #f0ad4e;
$danger: #d9534f;
$light: #f8f9fa;
$dark: #555;
$font-default: #3c3e3f;
$body-default: #414042;
$font-footer: #ffffff;
$border-btn-outline: #ffffff;
$font-btn-outline: #ffffff;
$headings-default: #000000;
$heading-6: #ffffff;
$heading-2: #ffffff;
$background-spinner: #d01417;
$font-back-icon: #ffffff;
$nav-link: #ffffff;
$nav-sub-header: #ffffff;
$font-quick-links: #ffffff;
$font-label-default: #000000;
$font-saved-details: #000000;
$border-card-notice: #d01417;
$font-card-notice: #000000;
$background-card-notice-error: #f9e4e4;
$font-btn-action: #75917f;
$font-btn-action-disabled: #ffffff;
$background-order-summary: #d01417;
$border-special-order-note: #555;
$border-error-notice: #d9534f;
$font-error-notice-span: #d9534f;
$background-google-places-suggestions: #ffffff;
$border-google-places-suggestions: #ffffff;
$background-google-places-suggestions-hover: #d3d3d3;
$background-delivery-address: #ffffff;
$background-details-section: #ffffff;
$border-form-control-focus: #414042;
$border-form-control: #000000;
$background-options-input: #cbd1d8;
$font-options-input: #ffffff;
$background-options-input-checked: #d01417;
$border-modal-email-capture-body-input: #000000;
$border-modal-email-capture-body-input-focus: #736f6e;
$border-modal-email-capture-footer-btn-primary-disabled: #6f6f6e;
$background-modal-email-capture-footer-btn-primary-disabled: #c6c6c6;
$background-info-box: #d01417;
$font-info-box: #ffffff;
$background-item-extras-loading-indicator: #d01417;
$border-item-extras-btn-default: #d01417;
$font-item-extras-btn-default: #d01417;
$font-item-extras-btn-default-done: #d01417;
$background-item-extras-btn-default: #ffffff;
$background-item-extras-btn-default-done: #ffffff;
$background-item-extras-btn-default-toggle: #d01417;
$font-order-items-btn-option: #3c3e3f;
$border-order-items-btn-option: #000000;
$background-order-items-btn-toggle: #f25a29;
$background-order-items-btn-white: #ffffff;
$background-order-items-btn-number: #ffffff;
$border-order-items-btn-number: #000000;
$font-order-items-btn-number: #000000;
$font-order-items-input-number: #414042;
$font-order-items-selected-item: #282828;
$background-message: #ffffff;
$font-order-items-selected-box-item: #d01417;
$font-order-items-right-btn-number: #555;
$font-modal-header-title: #3c3c3b;
$font-modal-paragraph: #f25a29;
$border-modal-form-control: #000000;
$border-modal-form-control-focus: #414042;
$background-order-flow-step: #c6c6c6;
$background-order-flow-step-active-passed: #d01417;
$background-order-flow-step-after: #ffffff;
$background-table-summary-header-cell: #d01417;
$background-table-summary-body-cells: #ffffff;
$border-table-summary-body-cell: #d01417;
$font-table-summary-body-cell-list: #000000;
$font-table-summary-item-title: #000000;
$font-table-summary-amount: #000000;
$font-table-summary-item-amount: #000000;
$font-table-summary-action-btn-chevron: #3c3e3f;
$font-table-summary-modifier-price: #414042;
$background-modal-payment-loader: #000000;
$border-payment-details-form-control: #000000;
$font-payment-loader-processing: #ffffff;
$font-place-order-quick-link-hover: #ffffff;
$background-place-order-fixed-btn-row: #414042;
$font-policy: #3c3c3b;
$font-heading-5: #3c3c3b;
$font-refund-heading-5: #d01417;
$font-btn-focus: #ffffff;
$background-btn-focus: #f25a29;
$border-btn-focus: #bfbfbf;
$background-spinner-circle: #d01417;
$font-start-powered-by: #ffffff;
$font-start-powered-by-link: #ffffff;
$border-start-actions: #ffffff;
$font-wait-order: #ffffff;
$font-wait-order-modal-title: #d01417;
$font-modal-order-cancelled-title: #3c3c3b;
$font-item-extras-btn-toggle: #ffffff;
$font-table-summary-header-cell: #ffffff;
$background-vendor-discovery: #3c3e3f;
$foreground-vendor-discovery: #ffffff;
$vendor-discovery-text: #555;
$vendor-discovery-header-columns: #ffffff;
$font-menu-list-subheaders: #ffffff;
$font-menu-list-item-price: #d01417;
$font-menu-list-item-title: #000000;
$border-table-summary-btn: #d01417;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
  'font-default': $font-default,
  'body-default': $body-default,
  'font-footer': $font-footer,
  'border-btn-outline': $border-btn-outline,
  'font-btn-outline': $font-btn-outline,
  'headings-default': $headings-default,
  'heading-6': $heading-6,
  'heading-2': $heading-2,
  'background-spinner': $background-spinner,
  'font-back-icon': $font-back-icon,
  'nav-link': $nav-link,
  'nav-sub-header': $nav-sub-header,
  'font-quick-links': $font-quick-links,
  'font-label-default': $font-label-default,
  'font-saved-details': $font-saved-details,
  'border-card-notice': $border-card-notice,
  'font-card-notice': $font-card-notice,
  'background-card-notice-error': $background-card-notice-error,
  'font-btn-action': $font-btn-action,
  'font-btn-action-disabled': $font-btn-action-disabled,
  'background-order-summary': $background-order-summary,
  'border-special-order-note': $border-special-order-note,
  'border-error-notice': $border-error-notice,
  'font-error-notice-span': $font-error-notice-span,
  'background-google-places-suggestions': $background-google-places-suggestions,
  'border-google-places-suggestions': $border-google-places-suggestions,
  'background-google-places-suggestions-hover':
    $background-google-places-suggestions-hover,
  'background-delivery-address': $background-delivery-address,
  'background-details-section': $background-details-section,
  'border-form-control-focus': $border-form-control-focus,
  'border-form-control': $border-form-control,
  'background-options-input': $background-options-input,
  'font-options-input': $font-options-input,
  'background-options-input-checked': $background-options-input-checked,
  'border-modal-email-capture-body-input':
    $border-modal-email-capture-body-input,
  'border-modal-email-capture-body-input-focus':
    $border-modal-email-capture-body-input-focus,
  'border-modal-email-capture-footer-btn-primary-disabled':
    $border-modal-email-capture-footer-btn-primary-disabled,
  'background-modal-email-capture-footer-btn-primary-disabled':
    $background-modal-email-capture-footer-btn-primary-disabled,
  'background-info-box': $background-info-box,
  'font-info-box': $font-info-box,
  'background-item-extras-loading-indicator':
    $background-item-extras-loading-indicator,
  'border-item-extras-btn-default': $border-item-extras-btn-default,
  'font-item-extras-btn-default': $font-item-extras-btn-default,
  'font-item-extras-btn-default-done': $font-item-extras-btn-default-done,
  'background-item-extras-btn-default': $background-item-extras-btn-default,
  'background-item-extras-btn-default-done':
    $background-item-extras-btn-default-done,
  'background-item-extras-btn-default-toggle':
    $background-item-extras-btn-default-toggle,
  'font-order-items-btn-option': $font-order-items-btn-option,
  'border-order-items-btn-option': $border-order-items-btn-option,
  'background-order-items-btn-toggle': $background-order-items-btn-toggle,
  'background-order-items-btn-white': $background-order-items-btn-white,
  'background-order-items-btn-number': $background-order-items-btn-number,
  'border-order-items-btn-number': $border-order-items-btn-number,
  'font-order-items-btn-number': $font-order-items-btn-number,
  'font-order-items-input-number': $font-order-items-input-number,
  'font-order-items-selected-item': $font-order-items-selected-item,
  'background-message': $background-message,
  'font-order-items-selected-box-item': $font-order-items-selected-box-item,
  'font-order-items-right-btn-number': $font-order-items-right-btn-number,
  'font-modal-header-title': $font-modal-header-title,
  'font-modal-paragraph': $font-modal-paragraph,
  'border-modal-form-control': $border-modal-form-control,
  'border-modal-form-control-focus': $border-modal-form-control-focus,
  'background-order-flow-step': $background-order-flow-step,
  'background-order-flow-step-active-passed':
    $background-order-flow-step-active-passed,
  'background-order-flow-step-after': $background-order-flow-step-after,
  'background-table-summary-header-cell': $background-table-summary-header-cell,
  'background-table-summary-body-cells': $background-table-summary-body-cells,
  'border-table-summary-body-cell': $border-table-summary-body-cell,
  'font-table-summary-body-cell-list': $font-table-summary-body-cell-list,
  'font-table-summary-item-title': $font-table-summary-item-title,
  'font-table-summary-amount': $font-table-summary-amount,
  'font-table-summary-item-amount': $font-table-summary-item-amount,
  'font-table-summary-action-btn-chevron':
    $font-table-summary-action-btn-chevron,
  'font-table-summary-modifier-price': $font-table-summary-modifier-price,
  'background-modal-payment-loader': $background-modal-payment-loader,
  'border-payment-details-form-control': $border-payment-details-form-control,
  'font-payment-loader-processing': $font-payment-loader-processing,
  'font-place-order-quick-link-hover': $font-place-order-quick-link-hover,
  'background-place-order-fixed-btn-row': $background-place-order-fixed-btn-row,
  'font-policy': $font-policy,
  'font-heading-5': $font-heading-5,
  'font-refund-heading-5': $font-refund-heading-5,
  'font-btn-focus': $font-btn-focus,
  'background-btn-focus': $background-btn-focus,
  'border-btn-focus': $border-btn-focus,
  'background-spinner-circle': $background-spinner-circle,
  'font-start-powered-by': $font-start-powered-by,
  'font-start-powered-by-link': $font-start-powered-by-link,
  'border-start-actions': $border-start-actions,
  'font-wait-order': $font-wait-order,
  'font-wait-order-modal-title': $font-wait-order-modal-title,
  'font-modal-order-cancelled-title': $font-modal-order-cancelled-title,
  'font-item-extras-btn-toggle': $font-item-extras-btn-toggle,
  'font-table-summary-header-cell': $font-table-summary-header-cell,
  'background-vendor-discovery': $background-vendor-discovery,
  'foreground-vendor-discovery': $foreground-vendor-discovery,
  'vendor-discovery-text': $vendor-discovery-text,
  'vendor-discovery-header-columns': $vendor-discovery-header-columns,
  'font-menu-list-subheaders': $font-menu-list-subheaders,
  'font-menu-list-item-price': $font-menu-list-item-price,
  'font-menu-list-item-title': $font-menu-list-item-title,
  'border-table-summary-btn': $border-table-summary-btn,
);

$image-paths: (
  'card_ae': '../static/images/card_ae.jpg',
  'card_mastercard': '../static/images/card_mastercard.jpg',
  'card_visa': '../static/images/card_visa.jpg',
  'card': '../static/images/card.png',
  'icon_arrow_more': '../static/images/icon_arrow_more.svg',
  'icon_arrow': '../static/images/icon_arrow.svg',
  'icon_protected': '../static/images/icon_protected.png',
  'indicator_spinner': '../static/images/indicator_spinner.gif',
  'left_icon': '../static/images/left_icon.png',
  'logo_120x120': '../static/images/logo_120x120.png',
  'logo_180x180': '../static/images/logo_180x180.png',
  'logo': '../static/images/logo.png',
  'map_marker_tablet': '../static/images/map_marker_tablet.png',
  'marker_small': '../static/images/marker_small.jpg',
  'marker': '../static/images/marker.jpg',
  'processing_icon': '../static/images/processing_icon.png',
  'loader': '../static/images/loader.png',
  'loader-1': '../static/images/loader-1.png',
  'loader-2': '../static/images/loader-2.png',
  'loader-3': '../static/images/loader-3.png',
  'loader-4': '../static/images/loader-4.png',
  'loader-5': '../static/images/loader-5.png',
  'loader-6': '../static/images/loader-6.png',
  'loader-7': '../static/images/loader-7.png',
  'loader-8': '../static/images/loader-8.png',
);

$homeBackgroundColor: #d01417;

$sm-breakout: 640px;
$fa-font-path: '../../static/fonts';
$fontFamily: 'Montserrat';

@font-face {
  font-family: 'Roboto';
  src: url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;?display=swap');
}

@font-face {
  font-family: 'ATC Duel';
  src: url('../static/fonts/atcduel-v10-webfont.woff');
}
