.table.table-summary {
  margin-bottom: 0;

  .btn {
    position: relative;
    color: map-get(
      $theme-colors,
      'primary'
    );
    border: 3px solid map-get(
      $theme-colors,
      'primary'
    ) !important;
    border-radius: 0px;
    width: 95% !important;
    text-align: center;
    display: block;
    margin: auto;
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 2px;

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      font-size: 21px;
      letter-spacing: 3px;
    }

    .border {
      border: 3px;
      color: map-get(
        $theme-colors,
        'border-table-summary-btn'
      );
    }
  }

  th {
    color: map-get($theme-colors, 'font-table-summary-header-cell');
    font-weight: 100;
    border: 0;
    background-color: map-get(
      $theme-colors,
      'background-table-summary-header-cell'
    );
    font-size: 12px;
    line-height: 25px;
    letter-spacing: 2px;
    padding: inherit 22px;
    
    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      font-size: 25px;
    }
  }

  td {
    text-transform: none;
    padding: 8px 10px;
    border-top: 0;
    border-bottom: 1px solid
      map-get($theme-colors, 'border-table-summary-body-cell');
    font-size: 16px;
    vertical-align: middle;
    text-align: left;
    background-color: map-get(
      $theme-colors,
      'background-table-summary-body-cells' 
    );

    ul {
      list-style: none;
      padding: 0;
      margin: 0 0 2px;

      li {
        color: map-get($theme-colors, 'font-table-summary-body-cell-list');
        padding: 0 0 0 10px;
        text-align: left;
        // background-image: url(../static/images/marker_small.jpg);
        // background-position: 0 8px;
        // background-repeat: no-repeat;

        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          font-size: 24px;
          // line-height: 30px;
          // padding: 0 0 0 20px;
          // background-image: url(../static/images/marker.jpg);
          // background-position: 0 15px;
        }
      }
    }
  }

  .item-title {
    font-size: 18px;
    line-height: 24px;
    font-family: 'ATC Duel';
    margin: 0 0 2px;
    text-transform: uppercase;
    color: map-get($theme-colors, 'font-table-summary-item-title');

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      font-size: 25px;
      margin: 0 0 6px;
      line-height: 44px;
    }
  }

  .amount {
    font-size: 18px;
    line-height: 24px;
    color: map-get($theme-colors, 'font-table-summary-amount');
    text-align: left;
    font-family: 'ATC Duel';
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      font-size: 25px;
      line-height: 44px;
    }
  }

  .item-price {
    font-size: 18px;
    line-height: 24px;
    color: map-get($theme-colors, 'font-table-summary-item-amount');
    font-family: 'ATC Duel';
    text-align: right;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      font-size: 25px;
      line-height: 44px;
    }
  }

  .action-btn {
    display: block;
    font-size: 18px;
    margin: auto;
    padding: 10px;
    text-align: center;
    border-radius: 0px;

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      font-size: 30px;
      line-height: 36px;
      letter-spacing: 0px;
      padding: 12px 10px 11px;
    }

    .fa-chevron-left {
      color: map-get($theme-colors, 'font-table-summary-action-btn-chevron');
    }
  }

  .modifier-Price {
    font-size: 20px;
    line-height: 24px;
    color: map-get($theme-colors, 'font-table-summary-modifier-price');
    font-family: 'ATC Duel';
    font-weight: 100;
    text-align: right;

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      font-size: 30px;
      line-height: 44px;
    }
  }
}
