.info-box {
  font-size: 19px;
  line-height: 20px;
  letter-spacing: 2px;

  color: map-get($theme-colors, 'font-info-box');
  text-align: center;
  padding: 9px 5px;
  background: map-get($theme-colors, 'background-info-box');

  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    font-size: 30px;
    padding: 15px 10px;
    letter-spacing: 4px;
  }

  .payment-box {
    margin-top: 50px;
  }
}
