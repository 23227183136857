/* stylelint-disable selector-max-id */
#followingBallsG {
  position: relative;
  width: 256px;
  height: 20px;
  line-height: 20px;
  margin: auto;

  @-moz-keyframes bounce_followingBallsG {
    0% {
      left: 0;
      background-color: $white;
    }
    50% {
      left: 236px;
      background-color: $black;
    }
    100% {
      left: 0;
      background-color: $white;
    }
  }
  @-webkit-keyframes bounce_followingBallsG {
    0% {
      left: 0;
      background-color: $white;
    }
    50% {
      left: 236px;
      background-color: $black;
    }
    100% {
      left: 0;
      background-color: $white;
    }
  }
  @-ms-keyframes bounce_followingBallsG {
    0% {
      left: 0;
      background-color: $white;
    }
    50% {
      left: 236px;
      background-color: $black;
    }
    100% {
      left: 0;
      background-color: $white;
    }
  }
  @-o-keyframes bounce_followingBallsG {
    0% {
      left: 0;
      background-color: $white;
    }

    50% {
      left: 236px;
      background-color: $black;
    }

    100% {
      left: 0;
      background-color: $white;
    }
  }
  @keyframes bounce_followingBallsG {
    0% {
      left: 0;
      background-color: $white;
    }
    50% {
      left: 236px;
      background-color: $black;
    }
    100% {
      left: 0;
      background-color: $white;
    }
  }
  .followingBallsG {
    background-color: $white;
    position: absolute;
    width: 20px;
    height: 20px;
    -moz-border-radius: 10px;
    -moz-animation-name: bounce_followingBallsG;
    -moz-animation-duration: 2.5s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-direction: linear;
    -webkit-border-radius: 10px;
    -webkit-animation-name: bounce_followingBallsG;
    -webkit-animation-duration: 2.5s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-direction: linear;
    -ms-border-radius: 10px;
    -ms-animation-name: bounce_followingBallsG;
    -ms-animation-duration: 2.5s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-direction: linear;
    -o-border-radius: 10px;
    -o-animation-name: bounce_followingBallsG;
    -o-animation-duration: 2.5s;
    -o-animation-iteration-count: infinite;
    -o-animation-direction: linear;
    border-radius: 10px;
    animation-name: bounce_followingBallsG;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    animation-direction: linear;
  }

  #followingBallsG_1 {
    -moz-animation-delay: 0s;
    -webkit-animation-delay: 0s;
    -ms-animation-delay: 0s;
    -o-animation-delay: 0s;
    animation-delay: 0s;
  }

  #followingBallsG_2 {
    -moz-animation-delay: 0.25s;
    -webkit-animation-delay: 0.25s;
    -ms-animation-delay: 0.25s;
    -o-animation-delay: 0.25s;
    animation-delay: 0.25s;
  }

  #followingBallsG_3 {
    -moz-animation-delay: 0.5s;
    -webkit-animation-delay: 0.5s;
    -ms-animation-delay: 0.5s;
    -o-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }

  #followingBallsG_4 {
    -moz-animation-delay: 0.75s;
    -webkit-animation-delay: 0.75s;
    -ms-animation-delay: 0.75s;
    -o-animation-delay: 0.75s;
    animation-delay: 0.75s;
  }
}
