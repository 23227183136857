.Start {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: none;
  height: 100%;

  > * {
    margin: 0 15px;
  }

  header {
    padding: 0 0 50px;
  }

  .logo-background {
    border-radius: 0%;
    width: 400px;
    height: 400px;
    // background-color: $white;
    overflow: hidden;
    align-self: flex-end;

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      width: 400px;
      height: 400px;
    }

    > .logo {
      background: url(map-get($image-paths, 'logo')) no-repeat center/contain;
      transform: scale(0.8);
    }
  }

  .header-background {
    // width: 310px;
    // height: 210px;
    // background: url(../static/images/bg_img_flowers.svg) no-repeat
    //   center/contain;
    justify-content: center;
    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      // width: 610px;
      // height: 340px;
    }
  }

  .site-title {
    padding: 0px;
    font-size: 0px;
    line-height: 0px;
    text-align: center;
    color: $white;
    letter-spacing: 1px;
    font-family: 'LucidaGrande';
    font-weight: bold;

    .title {
      font-size: 100px;
      line-height: 90px;
      letter-spacing: -5px;
      display: block;
    }

    .subtitle {
      font-size: 80px;
      line-height: 70px;
      letter-spacing: 0;
      display: block;
    }
  }

  .time-to-service { 
    font-family: 'ATC Duel';
    font-size: 22px;
    color: $primary;
    text-align: center;
    padding: 20px 0;
    
    span {
      line-height: 30px;
    }
  }

  .powered_by {
    padding: 20px 0;
    margin-bottom: 20px;
    text-align: center;
    color: map-get($theme-colors, 'font-start-powered-by');
    font-size: 16px;

    a {
      color: map-get($theme-colors, 'font-start-powered-by-link');
      text-decoration: none;
    }
  }

  .actions {
    .btn-primary {
      border: solid 3px map-get($theme-colors, 'border-start-actions');
    }
  }
}
