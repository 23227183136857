.Message {
  background-color: map-get($theme-colors, 'background-message');
  padding: 10px 0;
  margin: 0 0 5px 0;

  .text {
    font-size: 14px;
    line-height: 17px;
  }
}
