.OrderFlow {
  .OrderSteps {
    overflow: hidden;
    &.fixed-top {
      max-width: 100%;
    }
    .row {
      margin-left: 0;
      margin-right: 0;
    }
    .steps {
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-size: 12px;

      @media (min-width: map-get($grid-breakpoints, 'sm')) {
        font-size: 24px;
      }
      .step {
        width: 100%;
        text-align: center;
        background: map-get($theme-colors, 'background-order-flow-step');
        color: map-get($theme-colors, 'font-order-flow-step');
        padding: 10px 0;
        position: relative;
        padding-left: 15px;
        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          padding-left: 23px;
        }
        span {
          position: relative;
          z-index: 20;
        }

        &.active,
        &.passed {
          color: $white;
          background: map-get(
            $theme-colors,
            'background-order-flow-step-active-passed'
          );
          &:after {
            background: map-get(
              $theme-colors,
              'background-order-flow-step-active-passed'
            );
          }
        }
        &.step-1 {
          padding-left: 0px;
        }
        &.step-4 {
          &:after {
            display: none;
          }
        }
        &:after {
          content: '';
          height: 28px;
          width: 28px;
          display: block;
          position: absolute;
          right: -14px;
          bottom: 3px;
          z-index: 10;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          border-right: 3px solid
            map-get($theme-colors, 'background-order-flow-step-after');
          border-top: 3px solid
            map-get($theme-colors, 'background-order-flow-step-after');

          @media (min-width: map-get($grid-breakpoints, 'sm')) {
            height: 44px;
            width: 44px;
            right: -22px;
            bottom: 0;
          }
        }
      }
    }
  }
}
