.order-items {
  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    .item-group {
      h4 {
        font-size: 56px;
        line-height: 62px;
        margin: 45px 0 21px;
      }

      .order-item {
        .item-info {
          .item-title {
            font-size: 30px;
            line-height: 50px;
            margin: 0 0 -6px;
          }

          .item-price {
            font-size: 24px;
            line-height: 36px;
            font-weight: bold;
          }
        }

        .item-selection {
          .btn-options {
            line-height: 32px;
            border-width: 2px;
          }
        }
      }
    }
  }
  .modifier-error {
    color: $danger;
    font-size: 14px;
    padding: 10px 2px;
    font-weight: 700;
    line-height: 20px;
  }
  .item-group {
    margin-bottom: 15px;

    h4 {
      margin: -4px 0 8px 0;
      line-height: 62px;
      font-family: 'ATC Duel';
      font-weight: normal;
      text-align: center;
      font-size: 28px;
      color: map-get($theme-colors, 'font-menu-list-subheaders');

      @media (min-width: map-get($grid-breakpoints, 'sm')) {
        font-size: 56px;
      }
    }

    .order-item {
      padding: 12px 10px 8px 10px;
      margin: 0 0 2px;
      background: $white;
      box-sizing: border-box;

      .item-info {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .item-card {
          display: flex;
          flex-direction: column;
          flex-basis: calc(100% - 255px);
          justify-content: space-evenly;
          gap: 4px;

          .item-title {
            font-size: 17px;
            font-weight: 400;
            line-height: 19px;
            font-family: 'ATC Duel';
            margin: 0 0 -2px;
            color: map-get($theme-colors, 'font-menu-list-item-title');

            @media (min-width: map-get($grid-breakpoints, 'sm')) {
              font-size: 30px;
              line-height: 42px;
            }
          }

          .item-description {
            font-weight: 200;
            font-size: 12px;
            line-height: 19px;
            font-family: 'Montserrat', sans-serif;
            text-transform: none;
            @media (min-width: map-get($grid-breakpoints, 'sm')) {
              font-size: 18px;
              line-height: 28px;
            }
          }
        }
        .item-card.no-modifiers {
          flex-basis: calc(100% - 310px);
        }

        .item-card.menu-item-is-missing-image {
          padding-left: 95px;
          width: 225px;
          @media (min-width: map-get($grid-breakpoints, 'sm')) {
            padding-left: 120px;
            width: 250px;
          }
        }

        .thumbnail-image {
          padding-right: 15px;
          padding-left: 5px;
          img {
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            height: 75px;
            width: 75px;
            @media (min-width: map-get($grid-breakpoints, 'sm')) {
              height: 100px;
              width: 100px;
            }
          }
        }

        .red {
          color: map-get($theme-colors, 'font-menu-list-item-price');
        }

        .item-price {
          font-size: 14px;
          line-height: 28px;
          font-weight: bold;
          color: $dark;
          font-family: 'ATC Duel';
          color: map-get($theme-colors, 'font-menu-list-item-price');

          @media (min-width: map-get($grid-breakpoints, 'sm')) {
            font-size: 30px;
          }
        }
      }

      .item-selection {
        float: right;
        padding: 3px 0 0 3px;
        margin-left: auto;

        .btn-options {
          border-radius: 0px;
          width: auto;
          font-size: 19px;
          font-weight: 400px;
          padding: 10px 25px;
          color: map-get($theme-colors, 'font-order-items-btn-option');
          border-color: map-get($theme-colors, 'border-order-items-btn-option');
          border-width: 2px;
          border-style: solid;
        }

        .btn-red-toggle {
          color: $white;
          background-color: map-get(
            $theme-colors,
            'background-order-items-btn-toggle'
          );
        }

        .btn-white {
          color: map-get($theme-colors, 'font-order-items-btn-white');
          background-color: map-get(
            $theme-colors,
            'font-order-items-btn-white'
          );
        }

        .sold-message {
          font-size: 24px;
          display: inline-block;
          padding-top: 10px;
          padding-right: 10px;
          text-transform: none;
        }

        .button-number {
          width: 48px;
          height: 48px;
          color: map-get($theme-colors, 'font-order-items-btn-number');
          background-color: map-get(
            $theme-colors,
            'background-order-items-btn-number'
          );
          border: 2px solid
            map-get($theme-colors, 'border-order-items-btn-number');
          border-radius: 0px;
          position: relative;
          font-size: 35px;
          padding: 0;
          @media (min-width: map-get($grid-breakpoints, 'sm')) {
            width: 54px;
            height: 54px;
            border-width: 2px;
          }
        }

        .input-number {
          float: left;
          width: 40px;
          height: 28px;
          font-size: 30px;
          line-height: 50px;
          color: map-get($theme-colors, 'font-order-items-input-number');
          text-align: center;
          padding: 0;
          margin: 0;
          border: 0;

          @media (min-width: map-get($grid-breakpoints, 'sm')) {
            width: 79px;
            height: 56px;
            font-size: 42px;
            line-height: 55px;
          }
        }

        .red {
          color: map-get($theme-colors, 'primary');
        }

        .border-red {
          border: 2px solid map-get($theme-colors, 'primary');
        }

        .border-small {
          border: 1px solid #000000;
        }

        .held {
          color: #ffffff;
          background-color: map-get($theme-colors, 'primary');
        }
      }

      .selected-item-box {
        padding-top: 5px;
        .selected-item {
          color: map-get($theme-colors, 'font-order-items-selected-item');
        }
      }
    }
  }
}
