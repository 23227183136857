.Modal.menu-item-limit {
  .modal-body {
    font-size: 18px;
    line-height: 22px;
  }
  .modal-footer {
    .btn-primary {
      .fa-check {
        margin: 0 5px;
      }
    }
  }
}
