.footer {
  width: 100%;
  padding: 0px 0;
  font-size: 10px;
  color: #ffffff;

  button {
    text-decoration: underline;
    font-size: inherit;
  }

  .copyright {
    padding: 10px 0;
    line-height: 12px;
    text-align: center;
    text-transform: none;

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 20px;
    }
  }
}
