.details {
  padding: 10px 8px 12px;
  background: map-get($theme-colors, 'background-details-section');
  font-size: 16px;

  .field-phone {
    margin: 0 0 40px;

    label {
      font-size: 14px;
      text-align: center;
      margin-bottom: 10px;
      color: #000;

      @media (min-width: map-get($grid-breakpoints, 'sm')) {
        font-size: 18px;
      }
    }

    .warning {
      color: $danger;
    }

    input {
      @media (min-width: map-get($grid-breakpoints, 'sm')) {
        width: 350px;
      }
    }
  }

  .form-control {
    margin: auto;
    text-align: center;
    width: 250px;
    font-size: 24px;
    border-color: map-get($theme-colors, 'border-form-control');
    border-width: 2px;
    border-radius: 0px;

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      height: 65px;
      line-height: 45px;
      letter-spacing: 3px;
      padding: 2px 24px;
      border-width: 3px;
    }

    &:focus {
      border-color: map-get($theme-colors, 'border-form-control-focus');
      outline: 0;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px rgba(65, 64, 66, 0.6);
    }
  }

  .warning {
    color: $danger;
  }

  .cards {
    text-align: center;
    margin-top: 15px;

    img {
      display: inline-block;
      height: 35px;
      width: auto;
      margin: 0 0 0 -2px;

      @media (min-width: map-get($grid-breakpoints, 'sm')) {
        height: 45px;
        margin: 0 0 0 -3px;
      }
    }
  }

  .option-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    top: 13px;
    right: 0;
    bottom: 0;
    left: 0;
    height: 40px;
    width: 40px;
    background: map-get($theme-colors, 'background-options-input');
    border: 0;
    border-radius: 0;
    color: map-get($theme-colors, 'font-options-input');
    cursor: pointer;
    display: inline-block;
    margin-right: 0.5rem;
    outline: none;
    position: relative;
    z-index: 1000;
    padding: 0 !important;
  }

  .custom-radio {
    display: inline-block;
    margin-left: 10px;
  }

  .field {
    margin: 0 0 19px;
    position: relative;
  }

  .option-input:checked {
    background: map-get($theme-colors, 'background-options-input-checked');
  }

  .option-input:checked::before {
    height: 40px;
    width: 40px;
    position: absolute;
    content: '\2713';
    display: inline-block;
    // font-size: 26px;
    font-weight: 700;
    text-align: center;
    line-height: 40px;
  }
}
