.LoadingIndicator {  
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1059; // this value is the show the loading indicator behind the second modal
  background: rgba(0, 0, 0, 0.5);
  transition: opacity .15s linear;
  .Spinner {
    top: 50%;
    left: 50%;    
    position: absolute;    
  }  
}