.OrderSummaryInfo {
  margin-bottom: 35px;

  .payment-table {
    margin: 8px 0px 8px 0px;
    background-color: $white;
    width: 100%;

    tbody {
      tr {
        td {
          font-size: 14px;
          line-height: 20px;
          color: $white;
          font-weight: 100;
          vertical-align: bottom;
          text-align: right;
          border-top: 0;
          border-bottom: 0;
          color: $black;

          @media (min-width: map-get($grid-breakpoints, 'sm')) {
            font-size: 18px;
            line-height: 30px;
            border-bottom-width: 2px;
          }
        }
      }
    }
  }
}
