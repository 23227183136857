.card-notice {
  font-size: 14px;
  line-height: 18px;
  color: map-get($theme-colors, 'font-card-notice');
  text-align: center;
  padding: 7px 5px;
  margin: 0 -9px 28px;
  border: 2px solid map-get($theme-colors, 'border-card-notice');
  text-transform: none;

  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    font-size: 28px;
    line-height: 36px;
    padding: 14px 10px;
    margin: 0 -9px 57px;
    border-width: 4px;
  }

  &.error {
    background: map-get($theme-colors, 'background-card-notice-error');
  }
}

.notice {
  font-size: 15px;
  line-height: 18px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 0;
  margin: 0 0 22px;

  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 3px;
    margin: 0 0 41px;
  }
}
