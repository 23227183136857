.ConfirmOrder {
  padding: 25px 2px 0;
  height: 100vh;
  font-size: 16px;

  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    padding: 60px 2px 0;
  }

  .btn-primary {
    position: relative;
  }

  .summary-container {
    height: 100%;
  }

  .forward-icon {
    top: 33%;
  }

  .OrderSummaryInfo {
    background: map-get($theme-colors, 'background-order-summary');
  }

  .special-order-note {
    margin: 0 15px 15px;
    font-size: 16px;
    line-height: 25px;
    border: 1px solid map-get($theme-colors, 'background-special-order-note');
    padding: 5px;

    a {
      display: inline-block;
      color: inherit;
      text-decoration: underline;
    }
  }
}
