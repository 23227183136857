.checkbox.checkbox-confirm {
  padding: 10px 8px 3px 8px;
  background: $white;
  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    padding: 13px 12px 13px 16px;
    margin-bottom: 50px;
  }
}
.checkbox input[type='checkbox'] {
  opacity: 0;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  width: 18px;
  height: 18px;
}
.checkbox.checkbox-confirm input[type='checkbox'] {
  left: auto;
  right: 8px;
  top: 6px;
}
.checkbox.checkbox-confirm label {
  // font-size: 16px;
  letter-spacing: 1px;
  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    // font-size: 25px;
    line-height: 33px;
    padding: 0 0 0 64px;
  }
}
.checkbox input[type='checkbox']:checked + label:before {
  content: '\2713';
  font-weight: 700;
  color: $white;
  padding-left: 2px;
  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    width: 34px;
    height: 34px;
    top: -1px;
    padding-left: 6px;
  }
}
.checkbox input[type='checkbox'] + label:before {
  font-weight: 700;
  color: $white;
  padding-left: 2px;
  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    width: 34px;
    height: 34px;
    top: -1px;
    padding-left: 6px;
  }
}
.checkbox label {
  display: block;
  min-height: auto;
  // font-size: 14px;
  line-height: 17px;
  color: $black;
  font-weight: 400;
  text-transform: none;
  letter-spacing: 0;
  padding: 0 0 0 30px;
  margin: 0;
  position: relative;

  &::before {
    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      font-size: 28px;
      line-height: 32px;
    }
  }
}
.checkbox label:before {
  content: '';
  display: inline-block;
  width: 17px;
  height: 17px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: map-get($theme-colors, 'primary');
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}