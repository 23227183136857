.ConfirmContact {
  padding: 25px 2px 0;
  height: 100vh;

  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    padding: 60px 2px 0;
  }

  .btn-primary {
    position: relative;
  }

  .actions {
    // height: 100%;
    .action-btn.disabled {
      color: map-get($theme-colors, 'font-btn-action-disabled');
    }
    .action-btn {
      background: map-get($theme-colors, 'font-btn-action');
    }
  }
  .Footer {
    padding: 0;
    &.refund-policy {
      padding-top: 13px;
      padding-bottom: 0;
    }
  }
  .checkbox-confirm {
    &.payment-option {
      label {
        text-align: center;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }
  .forward-icon {
    top: 33%;
  }
}
