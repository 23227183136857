.PaymentLoaderModal {
  margin-top: 110px;
  .modal-content {
    background-color: map-get($theme-colors, 'background-modal-payment-loader');
    opacity: 0.9;
  }
  .modal-body {
    .processing-icon {
      height: 100px;
      background: url(map-get($image-paths, 'processing_icon')) center top
        no-repeat;
    }
  }
}
