.modal-body {
  text-transform: none;
  font-size: 18px;
  line-height: normal;

  .alert-modal-text {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
  }
}

.Modal1 {
  // @media (min-width: 640px) {
  //   .modal-content {
  //     max-width: 640px;
  //     margin: auto;
  //   }
  // }

  &.alert-modal {
    .modal-header {
      .modal-title {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        color: map-get($theme-colors, 'font-modal-header-title');
        text-transform: capitalize;
        font-family: 'Montserrat', sans-serif;
      }
    }

    .modal-body {
      .alert-modal-text {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 10px;
      }
    }

    .modal-footer {
      .btn.btn-primary {
        font-size: 19px;
        min-width: 110px;

        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          font-size: 25px;
        }

        i {
          &.fas.fa-check {
            margin: 0 5px;
          }
        }
      }
    }
  }

  &.coupon-modal {
    .modal-header {
      .modal-title {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        font-family: 'Montserrat', sans-serif;
      }
    }
    .modal-body {
      p {
        color: map-get($theme-colors, 'font-modal-paragraph');
        text-align: center;
      }

      .form-control {
        border-color: map-get($theme-colors, 'border-modal-form-control');
        border-width: 2px;
        text-align: left;
        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          height: 65px;
          font-size: 31px;
          line-height: 45px;
          letter-spacing: 3px;
          padding: 2px 24px;
          border-width: 3px;
          margin: 40px 0;
        }

        &:focus {
          border-color: #414042;
          outline: 0;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
            0 0 8px rgba(65, 64, 66, 0.6);
        }
      }
    }
    .modal-footer {
      a {
        &.btn {
          &.btn-primary {
            letter-spacing: 0;
            padding: 10px;
            &:first-child {
              margin-right: auto;
            }
          }
        }
      }
    }
  }

  &.network-disconnected {
    .modal-header {
      .modal-title {
        font-size: 24px;
        font-weight: 700;
        line-height: 24px;
        font-family: 'Montserrat', sans-serif;
        text-transform: none;
      }
    }
    .modal-body {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 10px;
      color: #000000;

      ul {
        padding-top: 0.5rem;
      }
    }
  }
}
