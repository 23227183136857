.item-extras {
  .item-extra {
    margin: 9px 0 0;
    // font-size: 14px;
    p {
      margin: 0 0 8px;
    }
  }

  .row-finish {
    padding: 21px 0 0;
  }

  .btn-default {
    display: inline-block;
    margin: 0 5px 4px 0;
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    padding: 4px 10px;
    border-width: 2px;
    border-style: solid;
    color: map-get($theme-colors, 'font-item-extras-btn-default');
    background-color: map-get(
      $theme-colors,
      'background-item-extras-btn-default'
    );
    border-color: map-get($theme-colors, 'border-item-extras-btn-default');
    transition: all 0.5s ease;
    border-radius: 0px;

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      // font-size: 26px;
      line-height: 32px;
      padding: 9px 22px;
      border-width: 2px;
    }

    &.btn-done {
      width: 100% !important;
      font-size: 21px;
      padding: 10px;
      background-color: map-get(
        $theme-colors,
        'background-item-extras-btn-default-done'
      );
      color: map-get($theme-colors, 'font-item-extras-btn-default-done');
    }

    &.btn-orange-toggle {
      color: map-get($theme-colors, 'font-item-extras-btn-toggle');
      background-color: map-get(
        $theme-colors,
        'background-item-extras-btn-default-toggle'
      );
    }
  }
}

.modifier-container {
  padding-left: 110px;
  flex-basis: unset;
  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    padding-left: 135px;
  }
  label {
    font-size: 12px;
    line-height: 15px;
    margin: 12px 0 4px;
    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      font-size: 18px;
      line-height: 22px;
    }
  }
}
