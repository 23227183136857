.discover-vendor-page {
  padding: 40px 2px 0;
  height: 100vh;

  .nav-header {
    padding: 0px;
    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      padding: 13px 0px 0px 0px;
      margin-bottom: 15px;
    }
  }

  .header-row {
    font-size: 12px;
    text-transform: uppercase;
    line-height: 18px;
    text-align: center;
    padding: 12px;
    background-color: $primary;
    display: flex;

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      font-size: 22px;
      padding: 24px;
    }

    .header-cols {
      float: left;
      position: relative;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px;
      width: calc(100% / 3);
      color: map-get($theme-colors, 'vendor-discovery-header-columns');
      font-weight: 700;
    }
  }

  .vendor-selection {
    font-size: 16px;
    line-height: 20px;
    background-color: map-get($theme-colors, 'background-vendor-discovery');

    .vendor {
      .content-row {
        display: flex;
        flex-direction: row;
        padding: 20px;
        margin: 0 0 5px;
        background-color: map-get($theme-colors, 'foreground-vendor-discovery');
        @media (max-width: map-get($grid-breakpoints, 'sm')) {
          padding: 10px;
        }

        .store-column {
          width: calc(100% / 2.4);
          float: left;
          position: relative;
          min-height: 1px;
          padding-left: 15px;
          padding-right: 15px;
          margin: auto;

          .row {
            align-items: center;
            justify-content: center;

            .vendor-icon {
              max-width: 100%;
              height: auto;
              vertical-align: middle;
              border: 0;
            }

            .option-name {
              text-align: center;
              font-size: 16px;
              font-weight: 600;
              line-height: 16px;
              color: map-get($theme-colors, 'vendor-discovery-text');
              padding: 2px 0;
              text-transform: uppercase;

              @media (min-width: map-get($grid-breakpoints, 'sm')) {
                font-size: 24px;
                line-height: 30px;
              }
            }

            .thumbnail-image {
              width: 75px;

              img {
                max-width: 100%;
                height: auto;
              }
            }
          }
        }

        .map-column {
          width: calc(100% / 6);
          float: left;
          position: relative;
          min-height: 1px;
          padding-left: 15px;
          padding-right: 15px;
          display: flex;
          align-items: center;
          justify-content: center;

          .toggleMap {
            background-color: map-get(
              $theme-colors,
              'foreground-vendor-discovery'
            );
            border: 0px;
            .mapIcon {
              background: url(map-get($image-paths, 'map_marker_tablet'))
                no-repeat center/contain;
              padding: 22px 0px 0px 0px;
              background-size: 18px 19px;

              @media (min-width: map-get($grid-breakpoints, 'sm')) {
                background-size: auto;
                padding: 46px 0px 0px 0px;
              }
            }

            .h5 {
              text-transform: uppercase;
              font-weight: 700;
              color: map-get($theme-colors, 'vendor-discovery-text');
              font-size: 14px;

              @media (min-width: map-get($grid-breakpoints, 'sm')) {
                font-size: 24px;
              }
            }
          }

          .toggleMap:hover {
            text-decoration: underline;
          }
        }

        .order-column {
          width: calc(100% / 2.4);
          float: left;
          position: relative;
          min-height: 1px;
          padding-left: 15px;
          padding-right: 15px;
          display: flex;
          align-items: center;
          justify-content: center;

          .option-link {
            cursor: pointer;
            display: block;
            width: 123px;
            line-height: 24px;
            color: map-get($theme-colors, 'vendor-discovery-text');
            text-decoration: none;
            text-align: center;
            padding: 8px 2px;
            border: 1px solid map-get($theme-colors, 'vendor-discovery-text');
            box-sizing: border-box;

            @media (min-width: map-get($grid-breakpoints, 'sm')) {
              padding: 23px 4px;
              width: 220px;
            }

            .text-primary-bold {
              color: $primary;
              font-family: 'ATC Duel';
            }

            .btn-tag {
              text-transform: none;
              font-weight: 700;
              font-size: 13px;

              @media (min-width: map-get($grid-breakpoints, 'sm')) {
                font-size: 18px;
              }
            }
          }

          .disabled {
            cursor: default;
          }
        }
      }
      .map-row {
        background-color: map-get($theme-colors, 'foreground-vendor-discovery');
        margin: 5px 0 5px 0;
        text-align: center;
        display: block;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }

  .vendor-unavailable {
    font-size: 16px;
    line-height: 20px;
    padding: 10px;
    margin: 0 0 5px;
    background-color: map-get($theme-colors, 'foreground-vendor-discovery');
    text-transform: none;
  }

  .btn-vendor-dropdown {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 2px;
    text-align: center;
    color: #ffffff;
  }
}
