.Payment {
  padding: 25px 2px 0;
  height: 100vh;

  @media (min-width: map-get($grid-breakpoints, 'sm')) {
    padding: 60px 2px 0;
  }

  .btn-primary {
    position: relative;
  }

  .nav-header {
    margin-bottom: 6px;
  }

  .vendor-row {
    text-align: center;
    

    img {
      width: 25px;
      margin: 5px;
    }
    
    .vendor-name {
      color: #fff;
      display: inline-block;
      text-transform: none;
      font-size: 18px;
    }
  }

  .details {
    .field {
      margin: 0 0 10px;

      &.field-code {
        &.credit-card-cvn {
          width: 110px;
          margin: auto;

          @media (min-width: map-get($grid-breakpoints, 'sm')) {
            width: 160px;
          }
        }
      }
    }

    .form-control {
      border-color: map-get(
        $theme-colors,
        'border-payment-details-form-control'
      );
      width: 100%;
      text-align: left;
      border-radius: 0px;
    }

    .field-protected {
      &:before {
        display: block;
        content: '';
        width: 15px;
        height: 20px;
        background: url(map-get($image-paths, 'icon_protected')) 0 0 no-repeat;
        background-size: 100% auto;
        position: absolute;
        right: 11px;
        bottom: 10px;
        z-index: 10;

        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          width: 31px;
          height: 39px;
          right: 23px;
          bottom: 14px;
        }
      }

      .credit-card-icon {
        width: 50px;
        height: 36px;
        display: inline-block;
        position: absolute;
        right: 32px;
        top: 27px;

        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          right: 64px;
          top: 63px;
        }
      }
    }

    .form-notice {
      font-size: 12px;
      line-height: 15px;
      color: $black;
      text-align: center;
      padding: 0 32px;
      margin: 10px 0 0;
      text-transform: none;

      @media (min-width: map-get($grid-breakpoints, 'sm')) {
        font-size: 18px;
        line-height: 29px;
      }
    }
  }

  /* stylelint-disable-next-line selector-max-id */
  #store-name {
    text-align: center;
    margin-bottom: 5px;
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 400;
    margin-top: 15px;
  }

  .toggle-card {
    margin-top: 20px;
    width: 100%;
    display: block;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 2px;
    padding: 4px 10px 5px;
    border-radius: 0px;
    text-transform: uppercase;
    color: $primary;
    border-width: 3px;

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      font-size: 30px;
      line-height: 36px;
      letter-spacing: 4px;
      padding: 12px 10px 11px;
      margin-bottom: 50px;
    }
  }
}
